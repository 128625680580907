import {
    Button,
    Drawer,
    PageHeader,
    Table,
    Space,
    Form,
    Input,
    Select, notification, Timeline
} from "antd";
import React, {useEffect, useState} from "react"

import {
    EditOutlined,
    DeleteOutlined, ExportOutlined,
    PlusOutlined, OrderedListOutlined
} from '@ant-design/icons';
import {Profile} from "../admin_api/Profile";
import {useForm} from "antd/lib/form/Form";
import {Option} from "antd/lib/mentions";
import {Company} from "../admin_api/Company";
import {DateTime} from "luxon";
import {ProfileTimeline} from "../admin_api/ProfileTimeline";
import {Config} from "../config";

const {TextArea} = Input;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function UsersPage() {

    const [visibleProfileDrawer, setVisibleProfileDrawer] = useState(false);
    const [visibleProfileTimelineDrawer, setVisibleProfileTimelineDrawer] = useState(false);
    const [profileIdInEdit, setProfileIdInEdit] = useState(false);
    const [profileIdForTimeline, setProfileIdForTimeline] = useState(false);
    const [data, setData] = useState(false);
    const [compagnies, setCompagnies] = useState([]);
    const [deletingUserId, setDeletingUserId] = useState(null);

    const [filterForm] = useForm();

    const columns = [
        {
            title: "Nom",
            render: (row) => (<>{row.firstname} {row.lastname}</>)
        },
        {
            title: 'Email',
            render: (row) => (<>{row.email}</>)
        },
        {
            title: 'Date de dernière activité',
            width: '220px',
            render: (row) => (<>
                {row.last_activity_at ? DateTime.fromISO(row.last_activity_at).toLocaleString(DateTime.DATETIME_SHORT) : null}
            </>)
        },
        {
            width: '70px',
            render: (row) => (<Space>
                    <Button shape="circle" icon={<EditOutlined/>} onClick={() => {
                        onEditProfileHandler(row.id)
                    }}/>
                    <Button shape="circle" icon={<OrderedListOutlined />} onClick={() => {
                        onShowProfileTImelineHandler(row.id)
                    }}/>
                    <Button loading={deletingUserId == row.id} type="danger" shape="circle" icon={<DeleteOutlined/>}
                            onClick={() => {
                                onDeleteProfileHandler(row.id)
                            }}/>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        loadProfiles();
        loadCompagnies();
    }, []);

    const loadProfiles = (filter) => {
        filter = filter || {}
        Profile.getAll(filter).then((json) => {
            setData(json);
        });
    }

    const loadCompagnies = (filter) => {
        filter = filter || {}
        Company.getAll(filter).then((json) => {
            setCompagnies(json);
        });
    }

    const onViewProfileHandler = (profileId) => {
        setProfileIdInEdit(profileId);
        setVisibleProfileDrawer(true);
    }

    const onAddProfileHandler = () => {
        if (!filterForm.getFieldValue('company_id')) {
            notification['error']({
                message: "Aucune entreprise selectionnée"
            })
        } else {
            setVisibleProfileDrawer(true);
        }
    }

    const onExportProfileBySponsorHandler = () => {
        // let values = filterForm.getFieldsValue();
        window.open(Config.apiUrl + '/admin/profiles/sponsor/export');
    }

    const onEditProfileHandler = (profileId) => {
        setProfileIdInEdit(profileId);
        setVisibleProfileDrawer(true);
    }


    const onShowProfileTImelineHandler = (profileId) => {
        setProfileIdForTimeline(profileId);
        setVisibleProfileTimelineDrawer(true);
    }

    const onDeleteProfileHandler = (profileId) => {
        setDeletingUserId(profileId);
        Profile.delete(profileId).then(() => {
            loadProfiles();
            setDeletingUserId(null);
        });
    }

    const onSaveProfileHandler = (profile) => {
        closeProfileDrawer();
        loadProfiles();
        notification['info']({
            message: "L'utilisateur a été créé",
            description: "Sont mot de passe est : " + profile.password,
            duration: 0
        })
    }

    const onCloseProfileDrawerHandler = () => {
        closeProfileDrawer();
    }

    const onCloseProfileTimelineDrawerHandler = () => {
        closeProfileTimelineDrawer();
    }

    const closeProfileDrawer = () => {
        setProfileIdInEdit(null);
        setVisibleProfileDrawer(false);
    }

    const closeProfileTimelineDrawer = () => {
        setProfileIdForTimeline(null);
        setVisibleProfileTimelineDrawer(false);
    }

    const onFinishFilterFormHandler = (values) => {
        loadProfiles({
            q: values
        });
    }

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Entreprises"
                subTitle="Retrouvez la liste de toutes les entreprises"
                extra={[
                    <Button type="primary" shape="round" icon={<PlusOutlined/>} onClick={onAddProfileHandler}>
                        Nouvel utilisateur
                    </Button>,
                    <Button type="primary" shape="round" icon={<ExportOutlined/>} onClick={onExportProfileBySponsorHandler}>
                        Exporter parrainages
                    </Button>
                ]}
            >

                <Form
                    form={filterForm}
                    layout="vertical"
                    onFinish={onFinishFilterFormHandler}
                >
                    <Form.Item
                        label="Entreprises"
                        name="company_id"
                    >
                        <Select>
                            {compagnies.map(company => {
                                return (<Option value={company.id}>{company.name}</Option>)
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Filtrer</Button>
                    </Form.Item>
                </Form>

                <Table
                    columns={columns}
                    dataSource={data}
                />

                <UserDrawer visible={visibleProfileDrawer} profileId={profileIdInEdit}
                            companyId={filterForm.getFieldValue('company_id')}
                            onSave={onSaveProfileHandler}
                            onClose={onCloseProfileDrawerHandler}/>
                <UserTimelineDrawer profile_id={profileIdForTimeline}
                                    visible={visibleProfileTimelineDrawer}
                                    onClose={onCloseProfileTimelineDrawerHandler}/>
            </PageHeader>
        </>
    )
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function UserDrawer(props) {

    const [form] = Form.useForm();
    const [profileInEdit, setProfileInEdit] = useState(null);

    useEffect(() => {
        form.resetFields();
        if (props.profileId) {
            Profile.getOne(props.profileId).then(profile => {
                setProfileInEdit(profile);
                form.setFieldsValue({
                    firstname: profile.firstname,
                    lastname: profile.lastname,
                    email: profile.email,
                    company_id: profile.company_id
                })
            });
        }
    }, [props.profileId]);

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();

        if (!props.profileId || props.profileId === null) {
            payload.company_id = props.companyId;
            Profile.create(payload).then((resp) => {
                props.onSave(resp);
            }).catch(() => {
                notification['error']({
                    message: "Impossible de créer l'utilisateur"
                });
            });
        } else {
            Profile.update(props.profileId, payload).then(() => {
                props.onSave();
            });
        }
    }

    const title = () => {
        if (props.profileId === null) {
            return "Nouvel utilisateur";
        } else {
            return "Édition de l'utilisateur";
        }
    }

    return (
        <>
            <Drawer title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item label="Nom" name="lastname" required>
                        <Input disabled={props.profileId}/>
                    </Form.Item>
                    <Form.Item label="Prénom" name="firstname" required>
                        <Input disabled={props.profileId}/>
                    </Form.Item>
                    <Form.Item label="email" name="email" required>
                        <Input disabled={props.profileId}/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}


/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function UserTimelineDrawer(props) {

    const [timeline, setTimeline] = useState([]);

    useEffect(() => {
        if (props.profile_id !== null) {
            loadTimeline();
        }
    }, [props.profile_id, props.visible])

    const loadTimeline = () => {
        ProfileTimeline.getOneOfProfile(props.profile_id).then((json) => {
            setTimeline(json);
        });
    }

    return (
        <>
            <Drawer title={"Évènements de l'utilisateur"} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                </Space>
            }>
                <Timeline>
                    {timeline.map((event) => (
                        <Timeline.Item>
                            <p>{event.name}</p>
                            <p style={{fontSize: "12px"}}>à {DateTime.fromISO(event.created_at).toLocaleString(DateTime.DATETIME_SHORT)} </p>
                        </Timeline.Item>
                    ))}
                </Timeline>
            </Drawer>
        </>
    )
}

export default UsersPage;

