import {jsonFetch} from "../api/JsonFetch";

let ProfileTimeline = {
    /**
     *
     */
    getOneOfProfile: async (profileId) => {
        let url = '/admin/profile/' + profileId + '/timeline';

        const response = await jsonFetch('GET', url);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },
}

export {ProfileTimeline};