import {jsonFetch} from "../api/JsonFetch";

let Profile = {
    /**
     *
     */
    getOne: async (id) => {
        let url = '/admin/profile/' + id;

        const response = await jsonFetch('GET', url);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

    /**
     *
     */
    getAll: async (payload) => {
        let url = '/admin/profiles';

        const response = await jsonFetch('POST', url, payload);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

    /**
     *
     */
    create: async (payload) => {
        let url = '/admin/profile';

        const response = await jsonFetch('POST', url, payload);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

    /**
     *
     */
    update: async (id, payload) => {
        let url = '/admin/profile/' + id;

        const response = await jsonFetch('PUT', url, payload);
        if (response.ok) {
            return response.json();
        }
        throw Error(response.statusText);
    },

    /**
     *
     */
    delete: async (id) => {
        let url = '/admin/profile/' + id;

        const response = await jsonFetch('DELETE', url);
        if (response.ok) {
            return null;
        }

        throw Error(response.statusText);
    },
}

export {Profile};