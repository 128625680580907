import React, { Component } from "react";
import { Layout, Typography, Row, Col, Image } from "antd";
import logo from '../../assets/Skani.png';
import "./Login.css";

const { Content } = Layout;
const { Title, Paragraph } = Typography;

class LayoutIdentificationPages extends Component {
  render() {
    return (
      <Content>
        <Row style={{ paddingTop: 100 }}>
          <Col span={12} offset={6}>
            <div className="logo-wrapper">
              <Image src={logo} alt="Logo" height={100} preview={false} />
            </div>
          </Col>
          <Col span={12} offset={6}>
            <Title style={{ textAlign: 'center' }}>{this.props.title}</Title>
            { (this.props.hasOwnProperty('subtitle')) ? (
              <Paragraph style={{ textAlign: 'center' }}>
               {this.props.subtitle}
              </Paragraph>
            ) : null }
          </Col>
        </Row>
        <div className="login-form-box">
          {this.props.children}
        </div>
      </Content>
    );
  }
}

export default LayoutIdentificationPages;
