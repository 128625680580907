import {Button, PageHeader, Table} from "antd";
import React, {useEffect, useState} from "react"


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function Home() {

    // Second, gather session data, if the user is not logged in, redirect to login
    useEffect(() => {
        // fetch('http://localhost:8000', {
        //     credentials: 'include'
        // }).then((res) => {
        //     return res.json();
        // }).then(json => {
        //     console.log('json', json)
        // })
        //
        // fetch('http://localhost:8000/user', {
        //     credentials: 'include'
        // }).then((res) => {
        //     return res.json();
        // }).then(json => {
        //     console.log('json', json)
        // })
    }, [])

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Accueil"
                extra={[ ]}
            >
                home
            </PageHeader>
        </>
    )
}

export default Home;

