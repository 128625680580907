import './App.css';
import 'animate.css/animate.css';
import 'react-responsive-modal/styles.css';

import React, {useEffect, useState} from "react"
import {V0alpha2Api, Configuration} from "@ory/client"

import {
    Avatar,
    Button, Col, Divider, Drawer,
    Layout, List,
    Menu, Row, Skeleton, Space,
    Tooltip
} from "antd";
import {Content, Footer, Header} from "antd/lib/layout/layout";
import {
    HomeOutlined,
    UnorderedListOutlined,
    PlusOutlined, UserOutlined
} from '@ant-design/icons';

import Sider from "antd/es/layout/Sider";
import logo from './assets/Skani.png';
import {
    BrowserRouter as Router, Link,
    Route,
    Routes,
    useNavigate
} from "react-router-dom";

import Home from "./pages/Home";
import Login from "./pages/login/Login";

import ory from "./helpers/ory";
import CompagniesPage from "./pages/Company";
import UsersPage from "./pages/Users";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function AppLayout(props) {

    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [visibleProfileDrawer, setVisibleProfileDrawer] = useState(false);

    let navigate = useNavigate();

    let onClickShowContactModalHandler = () => {
        setContactModalVisible(true);
    }

    let onClickVisibleProfileDrawerHandler = () => {
        setVisibleProfileDrawer(true)
    }

    let onCloseProfileDrawerHandler = () => {
        setVisibleProfileDrawer(false);
    }

    let onClickLogoutHandler = () => {

        ory.createSelfServiceLogoutFlowUrlForBrowsers().then((data) => {
            console.log(data)
            fetch(data.data.logout_url, {
                credentials: 'include',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                })
            }).then(() => {
                ory
                    .toSession()
                    .then(({data}) => {
                        // User has a session!
                        console.log('user', data)
                    }).catch(() => {
                        navigate('/login')
                    });
            })
        })
    }

    const getItems = () => {
        return [{
            'key': '1',
            'icon': <HomeOutlined/>,
            'label': <Link to={'/'}>Accueil</Link>
        }, {
            'key': '2',
            'icon': <UnorderedListOutlined/>,
            'label': <Link to={'/compagnies'}>Entreprises</Link>,
        }, {
            'key': '3',
            'icon': <UserOutlined/>,
            'label': <Link to={'/users'}>Utilisateurs</Link>,
        }];
    }

    let items = getItems();

    return (
        <div>
            <Layout style={{minHeight: '100vh'}}>
                <Sider width={250}
                       theme="light"
                       breakpoint="lg"
                       collapsedWidth="0"
                       onBreakpoint={broken => {
                       }}
                       onCollapse={(collapsed, type) => {
                       }}
                >
                    <div className="logo" style={{textAlign: "center", transition: "all 10ms ease"}}>
                        <img alt={'logo'} src={logo}/>
                    </div>
                    <Menu theme="light" mode="inline" defaultSelectedKeys={['4']} items={items}/>
                </Sider>
                <Layout>
                    <Header className="site-layout-sub-header-background">
                        <Row>
                            <Col span={12}></Col>
                            <Col span={12} style={{
                                textAlign: "right"
                            }}>
                                <Space>

                                    <Tooltip title="Nouveau contact">
                                        <Button shape="circle" icon={<PlusOutlined/>} size="large"
                                                onClick={onClickShowContactModalHandler}/>
                                    </Tooltip>

                                    <Divider type="vertical"/>

                                    <div>
                                        <Avatar onClick={onClickVisibleProfileDrawerHandler} style={{
                                            color: '#f56a00',
                                            backgroundColor: '#b1e4ce',
                                            margin: "0 0 5px 0",
                                            cursor: "pointer"
                                        }} size={40}>BD</Avatar>
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                    </Header>
                    <Content style={{margin: '24px 16px 0', minHeight: 360}}>
                        {props.children}
                    </Content>
                    <Footer style={{textAlign: 'center'}}>Skani</Footer>

                    <Drawer title="Votre profile" placement="right" onClose={onCloseProfileDrawerHandler}
                            visible={visibleProfileDrawer}>
                        <Row>
                            <Col span={24} style={{textAlign: "center"}}>
                                <Avatar size={150} style={{fontSize: "50px"}}>BD</Avatar>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{textAlign: "center"}}>
                                <p style={{
                                    fontSize: "26px",
                                    margin: "18px 0 0 0"
                                }}>Jérôme BULCOURT</p>
                                <p
                                    style={{
                                        margin: "0 0 126px 0"
                                    }}>Manager Actions Digitales</p>
                                <p>
                                    <Button shape="round" size={"large"}>
                                        Modifier
                                    </Button>
                                </p>

                                <p>
                                    <Button onClick={onClickLogoutHandler} shape="round" size={"large"}>
                                        Se déconnecter
                                    </Button>
                                </p>
                            </Col>
                        </Row>
                    </Drawer>

                </Layout>
            </Layout>
        </div>
    )
}

function SplashScreen() {
    return (<>Chargement ...</>)
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest}) {
    const [loading, setLoading] = useState(true);
    const [session, setSession] = useState()
    const [logoutUrl, setLogoutUrl] = useState()

    let navigate = useNavigate();

    useEffect(() => {
        ory
            .toSession()
            .then(({data}) => {
                // User has a session!
                setSession(data)
                ory.createSelfServiceLogoutFlowUrlForBrowsers().then(({data}) => {
                    // Get also the logout url
                    setLogoutUrl(data.logout_url);
                    setLoading(false);
                })
            })
            .catch(() => {
                navigate('/login')
            })
    }, [])

    if (loading) {
        return (<SplashScreen/>)
    }

    return !rest.withoutLayout ? (
        <AppLayout>{children}</AppLayout>
    ) : children;
}

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => {

    // Get your Ory url from .env
    // Or localhost for local development
    const basePath = process.env.REACT_APP_ORY_URL || "https://auth2.skani.xyz"
    const ory = new V0alpha2Api(
        new Configuration({
            basePath,
            baseOptions: {
                withCredentials: true,
            },
        }),
    )

    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/"
                           element={
                               <PrivateRoute>
                                   <Home/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/login"
                           element={
                               <Login/>
                           }
                    />
                    <Route path="/compagnies"
                           element={
                               <PrivateRoute>
                                   <CompagniesPage/>
                               </PrivateRoute>
                           }
                    />
                    <Route path="/users"
                           element={
                               <PrivateRoute>
                                   <UsersPage/>
                               </PrivateRoute>
                           }
                    />
                </Routes>
            </div>
        </Router>
    )
};

export default App;

