import {Configuration, V0alpha2Api} from "@ory/client";
import {Config} from "../config";

const basePath = process.env.REACT_APP_ORY_URL || Config.oryUrl
const ory = new V0alpha2Api(
    new Configuration({
        basePath,
        baseOptions: {
            withCredentials: true,
        },
    }),
)

export default ory;