import {Button, Upload, Drawer, PageHeader, message, Table, Space, Form, Input, AutoComplete, Col, Row} from "antd";
import React, {useEffect, useState} from "react"

import {
    EditOutlined,
    EyeOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {DateTime} from "luxon";
import {Company} from "../admin_api/Company";

const {TextArea} = Input;

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function CompagniesPage() {

    const [visibleSharedDocumentDrawer, setVisibleCompanyDrawer] = useState(false);
    const [companyIdInEdit, setCompanyIdInEdit] = useState(false);
    const [data, setData] = useState(false);

    const columns = [
        {
            title: "Nom de l'entreprise",
            render: (row) => (<>{row.name}</>)
        },
        {
            title: 'Date de création',
            width: '180px',
            render: (row) => (<>
                {DateTime.fromISO(row.created_at).toLocaleString(DateTime.DATETIME_SHORT)}
            </>)
        },
        {
            width: '70px',
            render: (row) => (<Space>
                    <Button shape="circle" icon={<EditOutlined/>} onClick={() => {
                        onEditCompanyHandler(row.id)
                    }}/></Space>
            ),
        },
    ];

    useEffect(() => {
        loadCompagnies();
    }, []);

    const loadCompagnies = () => {
        Company.getAll({}).then((json) => {
            setData(json);
        });
    }

    const onViewSharedDocumentHandler = (company_id) => {
        setCompanyIdInEdit(company_id);
        setVisibleCompanyDrawer(true);
    }

    const onLinkSharedDocumentHandler = (sharedDocument) => {
        window.open('http://localhost:8080/web/viewer.html?link=' + sharedDocument.short_link.public_id, '_blank')
    }

    const onAddCompanyHandler = () => {
        setVisibleCompanyDrawer(true);
    }

    const onEditCompanyHandler = (companyId) => {
        setCompanyIdInEdit(companyId);
        setVisibleCompanyDrawer(true);
    }

    const onSaveCompanyHandler = () => {
        closeCompanyDrawer();
        loadCompagnies();
    }

    const onCloseSharedDocumentDrawerHandler = () => {
        closeCompanyDrawer();
    }

    const closeCompanyDrawer = () => {
        setCompanyIdInEdit(null);
        setVisibleCompanyDrawer(false);
    }

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Entreprises"
                subTitle="Retrouvez la liste de toutes les entreprises"
                extra={[
                    <Button type="primary" shape="round" icon={<PlusOutlined/>} onClick={onAddCompanyHandler}>
                        Nouvelle entreprise
                    </Button>
                ]}
            >
                <Table
                    columns={columns}
                    dataSource={data}
                />

                <CompanyDrawer visible={visibleSharedDocumentDrawer} companyId={companyIdInEdit}
                               onSave={onSaveCompanyHandler}
                               onClose={onCloseSharedDocumentDrawerHandler}/>
            </PageHeader>
        </>
    )
}

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function CompanyDrawer(props) {

    const [form] = Form.useForm();
    const [companyInEdit, setCompanyInEdit] = useState(null);

    useEffect(() => {
        form.resetFields();
        if (props.companyId) {
            console.log(props.companyId)
            Company.getOne(props.companyId).then(company => {
                setCompanyInEdit(company);
                form.setFieldsValue({
                    name: company.name,
                    social_name: company.social_name,
                    address_1: company.address_1,
                    address_2: company.address_2,
                    address_3: company.address_3,
                    zipcode: company.zipcode,
                    country: company.country,
                    city: company.city,
                    rcs: company.rcs,
                    tva: company.tva,
                    comment: company.comment
                })
            });
        }
    }, [props.companyId]);

    const onSaveDrawerHandler = () => {
        let payload = form.getFieldsValue();

        if (!props.companyId || props.companyId === null) {
            Company.create(payload).then(() => {
                props.onSave();
            });
        } else {
            Company.update(props.companyId, payload).then(() => {
                props.onSave();
            });
        }
    }

    const title = () => {
        if (props.sharedDocumentId === null) {
            return "Nouvelle entreprise";
        } else {
            return "Édition de l'entreprise";
        }
    }

    return (
        <>
            <Drawer company_id={companyInEdit} title={title()} placement="left" onClose={props.onClose}
                    visible={props.visible} width={700} extra={
                <Space>
                    <Button onClick={props.onClose}>Annuler</Button>
                    <Button onClick={onSaveDrawerHandler} type="primary">
                        Enregistrer
                    </Button>
                </Space>
            }>
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Form.Item label="Société" name="name" required>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Raison sociale" name="social_name" required>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Adresse" name="address_1">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="address_2">
                        <Input/>
                    </Form.Item>
                    <Form.Item name="address_3">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Code postal" name="zipcode">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Ville" name="city">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Pays" name="country">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="RSC" name="rcs">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="TVA" name="tva">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Commentaire" name="comment">
                        <TextArea rows={10}/>
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    )
}

export default CompagniesPage;

